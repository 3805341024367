<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Rooms at Amchara Somerset</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Featured In Tabs -->
    <featured-in></featured-in>

    <!-- Rooms Section -->
    <div class="container mt-5">
      <div class="row py-3 text-center">
        <div class="col-md-10 mx-auto">
          <p>
            At Amchara Health Retreat, we strive to offer our customers the best
            experience while staying with us. So all of our rooms have been
            styled sympathetically to the traditional ‘Old England’ feel,
            enabling you to relax and recharge. Below is some information that
            explains what can be found in each of our rooms. If you’d like to
            enquire about availability,
            <router-link to="/enquiries">click here.</router-link>
          </p>
        </div>
      </div>
    </div>

    <!-- Economy Section -->
    <div class="container">
      <div class="row py-5 text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550" class="mb-5">Economy Rooms</h2>
          <div class="row">
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/economy_1.jpg"
                alt="Amcahra Health Retreat Economy Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/economy_2.jpg"
                alt="Amcahra Health Retreat Economy Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/economy_3.jpg"
                alt="Amcahra Health Retreat Economy Rooms"
                width="100%"
              />
            </div>
          </div>
          <p class="pt-4">
            Our economy rooms are nicely styled with traditional decor and
            comfortable furnishings. They have a small en-suite bathroom with
            shower and are positioned to the rear of the house. These rooms are
            suitable for individual guests or couples wishing to visit our
            retreat together.
          </p>
          <p style="color: #83c550">
            Room Facilities: 19" TV, DVD Player, En-Suite Bathroom / Shower Room
          </p>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <router-link to="/uk-pricing" class="btn btn-green-gradient"
            >VIEW PRICES</router-link
          >
          <router-link to="/enquiries" class="btn btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

    <!-- Standard Section -->
    <div class="container">
      <div class="row py-5 text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550" class="mb-5">Standard Rooms</h2>
          <div class="row">
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/standard_1.jpg"
                alt="Amcahra Health Retreat Standard Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/standard_2.jpg"
                alt="Amcahra Health Retreat Standard Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/standard_3.jpg"
                alt="Amcahra Health Retreat Standard Rooms"
                width="100%"
              />
            </div>
          </div>
          <p class="pt-4">
            These rooms are larger than the economy rooms and they have bigger
            bathrooms. They are positioned more favourably in the house with
            views to the back and side of the building. Some of the standard
            rooms may be shared by 2 individuals or friends.
          </p>
          <p style="color: #83c550">
            Room Facilities: 19" TV &middot; DVD Player &middot; En-Suite
            Bathroom / Shower Room
          </p>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <router-link to="/uk-pricing" class="btn btn-green-gradient"
            >VIEW PRICES</router-link
          >
          <router-link to="/enquiries" class="btn btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

    <!-- Superior Section -->
    <div class="container">
      <div class="row py-5 text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550" class="mb-5">Superior Rooms</h2>
          <div class="row">
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/superior_1.jpg"
                alt="Amcahra Health Retreat Superior Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/superior_2.jpg"
                alt="Amcahra Health Retreat Superior Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/superior_3.jpg"
                alt="Amcahra Health Retreat Superior Rooms"
                width="100%"
              />
            </div>
          </div>
          <p class="pt-4">
            here are 3 superior suites, that are bigger rooms and one of them
            has a 4 poster bed. These command better views than the standard
            rooms and typically have views from towards the front of the house
            though not directly over the main gardens. The largest Superior
            Suite, is at the top of the house and has a mini lounge area and
            faces to the back and side of the hotel. Superior Suites also come
            with desks and dressing tables.
          </p>
          <p style="color: #83c550">
            Room Facilities: 19" Television &middot; DVD Player &middot;
            En-Suite Bathroom &middot; Desk / Dressing Tables &middot; Armchair
          </p>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <router-link to="/uk-pricing" class="btn btn-green-gradient"
            >VIEW PRICES</router-link
          >
          <router-link to="/enquiries" class="btn btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

    <!-- Master Section -->
    <div class="container">
      <div class="row py-5 text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550" class="mb-5">Master Rooms</h2>
          <div class="row">
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/master_1.jpg"
                alt="Amcahra Health Retreat Master Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/master_2.jpg"
                alt="Amcahra Health Retreat Master Rooms"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/master_3.jpg"
                alt="Amcahra Health Retreat Master Rooms"
                width="100%"
              />
            </div>
          </div>
          <p class="pt-4">
            There are 3 master suites. These suites are located in the most
            advantageous positions in the house. Large sash windows facilitate
            an abundance of natural light. The position of 2 of the master
            suites allows one to indulge in the delightful panorama of the
            grounds and the front of the house. The largest and grandest of the
            master suites has a huge 4 poster bed and large Victorian bath. This
            room does not have views facing directly over the front gardens but
            is still positioned at the front of the building and overlooks the
            main driveway and onto lots of greenery. They all have sofas and
            coffee tables and they also come with 32inch televisions.
          </p>
          <p style="color: #83c550">
            Room Facilities: 32" Television &middot; DVD Player &middot;
            En-Suite Bathroom &middot; Desk / Dressing Tables &middot; Armchairs
            & Sofas
          </p>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <router-link to="/uk-pricing" class="btn btn-green-gradient"
            >VIEW PRICES</router-link
          >
          <router-link to="/enquiries" class="btn btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

    <!-- Testimonials -->
    <div style="margin-bottom: -3rem" class="mt-0">
      <testimonials></testimonials>
    </div>

    <!-- Newsletter -->
    <div>
      <newsletter-cta></newsletter-cta>
    </div>
  </div>
</template>

<script>
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import FeaturedIn from "./page-partials/FeaturedIn";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [require("@/assets/images/rooms/uk_header.jpg")],
    };
  },
  components: {
    Testimonials,
    NewsletterCta,
    FeaturedIn,
  },
};
</script>

<style>
</style>